declare var $: any;
declare var jQuery: any;

export class UIManager {
  public static initParallaxSlider() {
    $('#da-slider').cslider({
      current: 0,
      // index of current slide

      bgincrement: 50,
      // increment the background position
      // (parallax effect) when sliding

      autoplay: false,
      // slideshow on / off

      interval: 4000,
      // time between transitions
    });
  }

  public static initCubePortfolio() {
    // initialization of cubeportfolio
    $.HSCore.components.HSCubeportfolio.init('.cbp');
  }

  public static initCarousel() {
    // initialization of carousel
    $.HSCore.components.HSCarousel.init('.js-carousel');
  }

  public static initCounters() {
    // initialization of counters
    var counters = $.HSCore.components.HSCounter.init('[class*="js-counter"]');
  }

  public static initCountdown() {
    // initialization of countdowns
    var countdowns = $.HSCore.components.HSCountdown.init('.js-countdown', {
      yearsElSelector: '.js-cd-years',
      monthElSelector: '.js-cd-month',
      daysElSelector: '.js-cd-days',
      hoursElSelector: '.js-cd-hours',
      minutesElSelector: '.js-cd-minutes',
      secondsElSelector: '.js-cd-seconds',
    });
  }
}
