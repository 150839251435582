<!-- Footer -->
<div class="g-bg-black-opacity-0_9 g-color-white-opacity-0_8 g-py-60">
    <div class="container">
        <div class="row">
            <!-- Footer Content -->
            <div class="col-lg-3 col-md-6 g-mb-40 g-mb-0--lg">
                <a class="d-block g-mb-20" href="index.html">
                    <img class="img-fluid" src="../../../assets/img/logo/next-evolve-icon-black.jpg" alt="Logo">
                </a>
                <p>Next Evolve Limited Is A Service And Solutions-based Firm With Bespoke Practices</p>
                <p class="mb-0">By passion and hard work, we help our clients build stronger, more agile and innovative
                    businesses.</p>
            </div>
            <!-- End Footer Content -->

            <!-- Footer Content -->
            <div class="col-lg-3 col-md-6 g-mb-40 g-mb-0--lg">
                <div class="u-heading-v3-1 g-brd-white-opacity-0_3 g-mb-25">
                    <h2 class="u-heading-v3__title h6 text-uppercase g-brd-primary">Latest Posts</h2>
                </div>

                <!-- <article>
                    <h3 class="h6 g-mb-2">
                        <a class="g-color-white-opacity-0_8 g-color-white--hover" href="#">Incredible template</a>
                    </h3>
                    <div class="small g-color-white-opacity-0_6">May 8, 2017</div>
                </article>

                <hr class="g-brd-white-opacity-0_1 g-my-10">

                <article>
                    <h3 class="h6 g-mb-2">
                        <a class="g-color-white-opacity-0_8 g-color-white--hover" href="#">New features</a>
                    </h3>
                    <div class="small g-color-white-opacity-0_6">June 23, 2017</div>
                </article>

                <hr class="g-brd-white-opacity-0_1 g-my-10">

                <article>
                    <h3 class="h6 g-mb-2">
                        <a class="g-color-white-opacity-0_8 g-color-white--hover" href="#">New terms and conditions</a>
                    </h3>
                    <div class="small g-color-white-opacity-0_6">September 15, 2017</div>
                </article> -->
            </div>
            <!-- End Footer Content -->

            <!-- Footer Content -->
            <div class="col-lg-3 col-md-6 g-mb-40 g-mb-0--lg">
                <div class="u-heading-v3-1 g-brd-white-opacity-0_3 g-mb-25">
                    <h2 class="u-heading-v3__title h6 text-uppercase g-brd-primary">Useful Links</h2>
                </div>

                <nav class="text-uppercase1">
                    <ul class="list-unstyled g-mt-minus-10 mb-0">
                        <li class="g-pos-rel g-brd-bottom g-brd-white-opacity-0_1 g-py-10">
                            <h4 class="h6 g-pr-20 mb-0">
                                <a class="g-color-white-opacity-0_8 g-color-white--hover" routerLink="/about-us">About
                                    Us</a>
                                <i class="fa fa-angle-right g-absolute-centered--y g-right-0"></i>
                            </h4>
                        </li>
                        <li class="g-pos-rel g-brd-bottom g-brd-white-opacity-0_1 g-py-10">
                            <h4 class="h6 g-pr-20 mb-0">
                                <a class="g-color-white-opacity-0_8 g-color-white--hover"
                                    routerLink="/portfolios">Portfolio</a>
                                <i class="fa fa-angle-right g-absolute-centered--y g-right-0"></i>
                            </h4>
                        </li>
                        <li class="g-pos-rel g-brd-bottom g-brd-white-opacity-0_1 g-py-10">
                            <h4 class="h6 g-pr-20 mb-0">
                                <a class="g-color-white-opacity-0_8 g-color-white--hover" routerLink="/services">Our
                                    Services</a>
                                <i class="fa fa-angle-right g-absolute-centered--y g-right-0"></i>
                            </h4>
                        </li>
                        <li class="g-pos-rel g-brd-bottom g-brd-white-opacity-0_1 g-py-10">
                            <h4 class="h6 g-pr-20 mb-0">
                                <a class="g-color-white-opacity-0_8 g-color-white--hover" routerLink="/blog">Latest
                                    Jobs</a>
                                <i class="fa fa-angle-right g-absolute-centered--y g-right-0"></i>
                            </h4>
                        </li>
                        <li class="g-pos-rel g-py-10">
                            <h4 class="h6 g-pr-20 mb-0">
                                <a class="g-color-white-opacity-0_8 g-color-white--hover"
                                    routerLink="/contact-us">Contact Us</a>
                                <i class="fa fa-angle-right g-absolute-centered--y g-right-0"></i>
                            </h4>
                        </li>
                    </ul>
                </nav>
            </div>
            <!-- End Footer Content -->

            <!-- Footer Content -->
            <div class="col-lg-3 col-md-6">
                <div class="u-heading-v3-1 g-brd-white-opacity-0_3 g-mb-25">
                    <h2 class="u-heading-v3__title h6 text-uppercase g-brd-primary">Our Contacts</h2>
                </div>

                <address class="g-bg-no-repeat g-line-height-2 g-mt-minus-4"
                    style="background-image: url(../../../assets/img/maps/map2.png);">
                    4, Wilson Estate, Off Bode Edun
                    <br>
                    Alagbole, Lagos
                    <br>
                    Mobile: +234 803 465 9171
                    <br>
                            +234 812 222 3411
                    <br>
                            +234 802 430 1965
                    <br>
                    Office: +234 906 371 5287
                    <br>
                    Email:
                    <a href="mailto:next.evolvnig@gmail.com" class="">next.evolvnig@gmail.com</a>
                </address>
            </div>
            <!-- End Footer Content -->
        </div>
    </div>
</div>
<!-- End Footer -->

<!-- Copyright Footer -->
<footer class="g-bg-gray-dark-v1 g-color-white-opacity-0_8 g-py-20">
    <div class="container">
        <div class="row">
            <div class="col-md-8 text-center text-md-left g-mb-15 g-mb-0--md">
                <div class="d-lg-flex">
                    <small class="d-block g-font-size-default g-mr-10 g-mb-10 g-mb-0--md">2021 © All Rights
                        Reserved.</small>
                    <ul class="u-list-inline">
                        <li class="list-inline-item">
                            <a href="#">Privacy Policy</a>
                        </li>
                        <li class="list-inline-item">
                            <span>|</span>
                        </li>
                        <li class="list-inline-item">
                            <a href="#">Terms of Use</a>
                        </li>
                    </ul>
                </div>
            </div>

            <div class="col-md-4 align-self-center">
                <ul class="list-inline text-center text-md-right mb-0">
                    <li class="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top" title="Facebook">
                        <a href="#" class="g-color-white-opacity-0_5 g-color-white--hover">
                            <i class="fa fa-facebook"></i>
                        </a>
                    </li>
                    <li class="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top" title="Skype">
                        <a href="#" class="g-color-white-opacity-0_5 g-color-white--hover">
                            <i class="fa fa-skype"></i>
                        </a>
                    </li>
                    <li class="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top" title="Linkedin">
                        <a href="#" class="g-color-white-opacity-0_5 g-color-white--hover">
                            <i class="fa fa-linkedin"></i>
                        </a>
                    </li>
                    <li class="list-inline-item g-mx-10" data-toggle="tooltip" data-placement="top" title="Twitter">
                        <a href="#" class="g-color-white-opacity-0_5 g-color-white--hover">
                            <i class="fa fa-twitter"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</footer>
<!-- End Copyright Footer -->