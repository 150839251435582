import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { NotfoundComponent } from './shared/notfound/notfound.component';
import { ComingsoonComponent } from './shared/comingsoon/comingsoon.component';
import { ContactusComponent } from './pages/contactus/contactus.component';
import { AboutusComponent } from './pages/aboutus/aboutus.component';
import { ServicesComponent } from './pages/services/services.component';
import { PortfolioComponent } from './pages/portfolio/portfolio.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    component: DashboardComponent,
    children: [
      { path: 'home', component: HomeComponent },
      { path: 'about-us', component: AboutusComponent },
      { path: 'services', component: ServicesComponent },
      { path: 'portfolios', component: PortfolioComponent },
      { path: 'contact-us', component: ContactusComponent },
      { path: 'blog', component: ComingsoonComponent },
      { path: 'notFound', component: NotfoundComponent },
    ],
  },
  { path: '**', redirectTo: 'notFound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routingComponents = [
  DashboardComponent,
  HomeComponent,
  NotfoundComponent,
  ComingsoonComponent,
  ContactusComponent,
  AboutusComponent,
  ServicesComponent,
  PortfolioComponent,
];
