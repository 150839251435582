import { Component, OnInit } from '@angular/core';
import { UIManager } from '../../util/ui-manager';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    UIManager.initCarousel();
  }

}
