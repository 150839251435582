<!-- Header -->
<header id="js-header" class="u-header u-header--toggle-section u-header--change-appearance"
    data-header-fix-moment="300">
    <div class="u-header__section u-header__section--dark g-bg-black g-transition-0_3 g-py-10"
        data-header-fix-moment-exclude="g-py-10" data-header-fix-moment-classes="u-shadow-v18 g-py-0">
        <nav class="navbar navbar-expand-lg">
            <div class="container">
                <!-- Responsive Toggle Button -->
                <button
                    class="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-3 g-right-0"
                    type="button" aria-label="Toggle navigation" aria-expanded="false" aria-controls="navBar"
                    data-toggle="collapse" data-target="#navBar">
                    <span class="hamburger hamburger--slider">
                        <span class="hamburger-box">
                            <span class="hamburger-inner"></span>
                        </span>
                    </span>
                </button>
                <!-- End Responsive Toggle Button -->

                <!-- Logo -->
                <a href="../../../index.html" class="navbar-brand">
                    <img src="../../../assets/img/logo/next-evolve-header.png" alt="Image Description"/>
                </a>
                <!-- End Logo -->

                <!-- Navigation -->
                <div class="collapse navbar-collapse align-items-center flex-sm-row g-pt-10 g-pt-5--lg" id="navBar">
                    <ul class="navbar-nav text-uppercase g-font-weight-600 ml-auto">
                        <li class="nav-item g-mx-20--lg">
                            <a routerLink="/home" class="nav-link px-0">Home </a>
                        </li>
                        <li class="nav-item g-mx-20--lg">
                            <a routerLink="/about-us" class="nav-link px-0">About Us </a>
                        </li>
                        <li class="nav-item g-mx-20--lg">
                            <a routerLink="/portfolios" class="nav-link px-0">Portfolio </a>
                        </li>
                        <li class="nav-item g-mx-20--lg">
                            <a routerLink="/services" class="nav-link px-0">Services </a>
                        </li>
                        <li class="nav-item g-mx-20--lg">
                            <a href="https://medium.com/@next.evolvnig" target="_blank" class="nav-link px-0">Blog </a>
                        </li>
                        <li class="nav-item g-ml-20--lg g-mr-0--lg">
                            <a routerLink="/contact-us" class="nav-link px-0">Contact Us </a>
                        </li>
                    </ul>
                </div>
                <!-- End Navigation -->
            </div>
        </nav>
    </div>
</header>
<!-- End Header -->