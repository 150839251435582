<main class="g-min-height-100vh g-flex-centered g-bg-size-cover g-bg-cover g-bg-bluegray-opacity-0_3--after g-pa-15"
    style="background-image: url(assets/img-temp/1920x1080/img1.jpg);">
    <div class="text-center g-max-width-700 g-flex-centered-item g-z-index-1 g-color-white">
        <h1 class="display-3 g-mb-30">Coming Soon</h1>
        <h2 class="display-4 g-mb-30">Why Next Evolve? </h2>
        <p class="g-font-size-22 g-mb-50">Distinguished References, Fostering Relationships, Know-How, Not Products but
            Solutions and Advanced Security Intellectual Property</p>

        <div class="js-countdown row g-z-index-1" data-end-date="2021/11/01" data-month-format="%m"
            data-days-format="%D" data-hours-format="%H" data-minutes-format="%M" data-seconds-format="%S">
            <div class="col-3 g-py-10">
                <strong
                    class="js-cd-days d-block display-4 mx-auto g-width-70 g-height-70 g-bg-white-opacity-0_2 g-font-weight-700 g-font-size-25 g-rounded-50x g-line-height-1 g-py-20 mb-2"></strong>
                <em class="g-font-style-normal g-font-size-20">Days</em>
            </div>

            <div class="col-3 g-brd-left g-brd-white-dark-v3 g-py-10">
                <strong
                    class="js-cd-hours d-block display-4 mx-auto g-width-70 g-height-70 g-bg-white-opacity-0_2 g-font-weight-700 g-font-size-25 g-rounded-50x g-line-height-1 g-py-20 g-px-10 mb-2"></strong>
                <em class="g-font-style-normal g-font-size-20">Hours</em>
            </div>

            <div class="col-3 g-brd-left g-brd-white-dark-v3 g-py-10">
                <strong
                    class="js-cd-minutes d-block display-4 mx-auto g-width-70 g-height-70 g-bg-white-opacity-0_2 g-font-weight-700 g-font-size-25 g-rounded-50x g-line-height-1 g-py-20 g-px-10 mb-2"></strong>
                <em class="g-font-style-normal g-font-size-20">Minutes</em>
            </div>

            <div class="col-3 g-brd-left g-brd-white-dark-v3 g-py-10">
                <strong
                    class="js-cd-seconds d-block display-4 mx-auto g-width-70 g-height-70 g-bg-white-opacity-0_2 g-font-weight-700 g-font-size-25 g-rounded-50x g-line-height-1 g-py-20 g-px-10 mb-2"></strong>
                <em class="g-font-style-normal g-font-size-20">Seconds</em>
            </div>
        </div>
    </div>
</main>