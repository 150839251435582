<!-- Promo Block -->
<section class="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall "
    data-options='{direction: "fromtop", animation_duration: 25, direction: "reverse"}'>
    <!-- Parallax Image -->
    <div class="divimage dzsparallaxer--target w-100 g-bg-cover g-bg-size-cover g-bg-pos-top-center g-bg-black-opacity-0_3--after"
        style="height: 140%; background-image: url(../../assets/img-temp/1920x800/img11.jpg);"></div>
    <!-- End Parallax Image -->

    <!-- Promo Block Content -->
    <div class="container g-color-white text-center g-py-150">
        <h2 class="h3 text-uppercase mb-2">Our services</h2>
        <p class="g-font-weight-600 g-font-size-40 text-uppercase">Creative freedom matters</p>
    </div>
    <!-- Promo Block Content -->
</section>
<!-- End Promo Block -->

<!-- Section -->
<section class="container g-pt-100 g-pb-40">
    <div class="row">
        <div class="col-lg-6 align-self-center g-mb-60">
            <img class="img-fluid" src="../../assets/img/custom/ai-bg-tr.png" alt="Image Description" width="600"
                height="400">
        </div>

        <div class="col-lg-6 align-self-center g-mb-60">
            <div class="mb-2">
                <h2 class="h3 g-color-black text-uppercase mb-2">We are the evolution</h2>
                <div class="d-inline-block g-width-35 g-height-2 g-bg-primary mb-2"></div>
            </div>
            <div class="mb-5">
                <p class="mb-5">We are an ambitious, workaholic, but apart from that, pretty simple company. Whether
                    it's
                    branding, ERP, UI + UX we've got you covered.</p>

                <ul class="list-unstyled g-font-size-13 mb-0">
                    <li>
                        <i class="d-inline-block g-color-primary mr-2 mb-3 fa fa-check"></i>
                        Jacks of all. experts in all.
                    </li>
                    <li>
                        <i class="d-inline-block g-color-primary mr-2 mb-3 fa fa-check"></i>
                        It's good to virtually meet you.
                    </li>
                    <li>
                        <i class="d-inline-block g-color-primary mr-2 mb-3 fa fa-check"></i>
                        A crew of creative doers.
                    </li>
                    <li>
                        <i class="d-inline-block g-color-primary mr-2 mb-3 fa fa-check"></i>
                        Let's create something great.
                    </li>
                </ul>
            </div>
            <a class="btn btn-md u-btn-outline-primary g-font-size-default g-rounded-50 g-py-10 mr-2"
                routerLink="/portfolios">Explore
                more</a>
        </div>
    </div>
</section>
<!-- End Section -->

<!-- Our Services -->
<section class="g-bg-secondary g-py-100">
    <div class="container">
        <header class="text-center g-width-80x--md mx-auto g-mb-70">
            <div class="u-heading-v6-2 text-center text-uppercase g-mb-20">
                <h6 class="g-font-size-12 g-font-weight-600">Our Services</h6>
                <h2 class="h3 u-heading-v6__title g-brd-primary g-color-gray-dark-v2 g-font-weight-600">What We Do</h2>
            </div>
        </header>

        <!-- Icon Blocks -->
        <div class="row">
            <div class="col-lg-6 g-mb-30">
                <!-- Icon Blocks -->
                <div class="u-shadow-v21 g-bg-white rounded g-pa-30">
                    <div class="media g-mb-15">
                        <div class="d-flex mr-4">
                            <span class="g-color-gray-dark-v5 g-font-size-26">
                                <i class="icon-communication-156 u-line-icon-pro"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h3 class="h5 g-color-black mb-20">Information Technology</h3>
                            <div class="g-width-30 g-brd-bottom g-brd-primary g-my-15"></div>
                            <p class="g-color-gray-dark-v4 g-mb-0">We offer Information technology services to cover a
                                wide scope of problems in our demographic.</p>
                        </div>
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>

            <div class="col-lg-6 g-mb-30">
                <!-- Icon Blocks -->
                <div class="u-shadow-v21 g-bg-white rounded g-pa-30">
                    <div class="media g-mb-15">
                        <div class="d-flex mr-4">
                            <span class="g-color-gray-dark-v5 g-font-size-26">
                                <i class="icon-finance-086 u-line-icon-pro"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h3 class="h5 g-color-black mb-20">Project Management</h3>
                            <div class="g-width-30 g-brd-bottom g-brd-primary g-my-15"></div>
                            <p class="g-color-gray-dark-v4 g-mb-0">Combining best industry practices in PMI and PRINCE2,
                                we deliver quality and best value Project management services.</p>
                        </div>
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>
        </div>
        <!-- End Icon Blocks -->

        <!-- Icon Blocks -->
        <div class="row">
            <div class="col-lg-6 g-mb-30">
                <!-- Icon Blocks -->
                <div class="u-shadow-v21 g-bg-white rounded g-pa-30">
                    <div class="media g-mb-15">
                        <div class="d-flex mr-4">
                            <span class="g-color-gray-dark-v5 g-font-size-26">
                                <i class="icon-communication-007 u-line-icon-pro"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h3 class="h5 g-color-black mb-20">Business Process Outsourcing</h3>
                            <div class="g-width-30 g-brd-bottom g-brd-primary g-my-15"></div>
                            <p class="g-color-gray-dark-v4 g-mb-0">Our attitude is to fully understand our clients’
                                needs, values and strategic direction and sync our collaborative operations to align
                                such that overall value added is beyond just meeting Service Level agreements.</p>
                        </div>
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>

            <div class="col-lg-6 g-mb-30">
                <!-- Icon Blocks -->
                <div class="u-shadow-v21 g-bg-white rounded g-pa-30">
                    <div class="media g-mb-15">
                        <div class="d-flex mr-4">
                            <span class="g-color-gray-dark-v5 g-font-size-26">
                                <i class="icon-sport-035 u-line-icon-pro"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h3 class="h5 g-color-black mb-20">Capacity Building</h3>
                            <div class="g-width-30 g-brd-bottom g-brd-primary g-my-15"></div>
                            <p class="g-color-gray-dark-v4 g-mb-0">At Next Evolve LTD, we consider Training as part of
                                capacity building. We believe that human capital is price- less; hence We consider human
                                capacity building as a key strategic leverage in organi- zations.</p>
                        </div>
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>
        </div>
        <!-- End Icon Blocks -->

        <!-- Icon Blocks -->
        <div class="row">
            <div class="col-lg-6 g-mb-30">
                <!-- Icon Blocks -->
                <div class="u-shadow-v21 g-bg-white rounded g-pa-30">
                    <div class="media g-mb-15">
                        <div class="d-flex mr-4">
                            <span class="g-color-gray-dark-v5 g-font-size-26">
                                <i class="icon-electronics-119 u-line-icon-pro"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h3 class="h5 g-color-black mb-20">Alternate Power Solutions</h3>
                            <div class="g-width-30 g-brd-bottom g-brd-primary g-my-15"></div>
                            <p class="g-color-gray-dark-v4 g-mb-0">At Next Evolve LTD are committed to providing innova-
                                tive, cost effective, environmentally friendly and Reliable power and renewable energy
                                solutions.</p>
                        </div>
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>

            <div class="col-lg-6 g-mb-30">
                <!-- Icon Blocks -->
                <div class="u-shadow-v21 g-bg-white rounded g-pa-30">
                    <div class="media g-mb-15">
                        <div class="d-flex mr-4">
                            <span class="g-color-gray-dark-v5 g-font-size-26">
                                <i class="icon-communication-158 u-line-icon-pro"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h3 class="h5 g-color-black mb-20">Telecommunication</h3>
                            <div class="g-width-30 g-brd-bottom g-brd-primary g-my-15"></div>
                            <p class="g-color-gray-dark-v4 g-mb-0">Our Telecom services now include fixed-network
                                services (data retail, Internet retail, voice retail and wholesale) and mobile services.
                            </p>
                        </div>
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>
        </div>
        <!-- End Icon Blocks -->
    </div>
</section>
<!-- End Our Services -->

<section>
    <div class="container g-pt-100 g-pb-40">
        <div class="row justify-content-center g-mb-60">
            <div class="col-lg-7">
                <!-- Heading -->
                <div class="text-center">
                    <h2 class="h3 g-color-black text-uppercase mb-2">Our process</h2>
                    <div class="d-inline-block g-width-35 g-height-2 g-bg-primary mb-2"></div>
                    <p class="lead mb-0">A simple walkthrough on the steps taken to produce the very best product
                        imaginable for our clients.</p>
                </div>
                <!-- End Heading -->
            </div>
        </div>

        <!-- Static Process -->
        <div class="row">
            <div class="col-sm-6 col-lg-3 g-mb-60">
                <!-- Static Process -->
                <div class="text-center">
                    <i class="u-dot-line-v1-2 g-brd-transparent--before g-brd-gray-light-v2--after g-mb-20">
                        <span class="u-dot-line-v1__inner g-bg-white g-bg-primary--before g-brd-gray-light-v2"></span>
                    </i>
                    <h3 class="h5 g-color-black mb-20">Consult</h3>
                    <p class="mb-0">This is where we sit down, grab a cup of coffee and dial in the details.</p>
                </div>
                <!-- End Static Process -->
            </div>

            <div class="col-sm-6 col-lg-3 g-mb-60">
                <!-- Static Process -->
                <div class="text-center">
                    <i class="u-dot-line-v1-2 g-brd-gray-light-v2--before g-brd-gray-light-v2--after g-mb-20">
                        <span class="u-dot-line-v1__inner g-bg-white g-bg-primary--before g-brd-gray-light-v2"></span>
                    </i>
                    <h3 class="h5 g-color-black mb-20">Plan</h3>
                    <p class="mb-0">Now that we've aligned the details, it's time to get things organized.</p>
                </div>
                <!-- End Static Process -->
            </div>

            <div class="col-sm-6 col-lg-3 g-mb-60">
                <!-- Static Process -->
                <div class="text-center">
                    <i class="u-dot-line-v1-2 g-brd-gray-light-v2--before g-brd-gray-light-v2--after g-mb-20">
                        <span class="u-dot-line-v1__inner g-bg-white g-bg-primary--before g-brd-gray-light-v2"></span>
                    </i>
                    <h3 class="h5 g-color-black mb-20">Create</h3>
                    <p class="mb-0">We begin to create and test the product with our development team.</p>
                </div>
                <!-- End Static Process -->
            </div>

            <div class="col-sm-6 col-lg-3 g-mb-60">
                <!-- Static Process -->
                <div class="text-center">
                    <i class="u-dot-line-v1-2 g-brd-gray-light-v2--before g-brd-transparent--after g-mb-20">
                        <span class="u-dot-line-v1__inner g-bg-white g-bg-primary--before g-brd-gray-light-v2"></span>
                    </i>
                    <h3 class="h5 g-color-black mb-20">Release</h3>
                    <p class="mb-0">Your product is all dressed up and ready to party, it's time to release.</p>
                </div>
                <!-- End Static Process -->
            </div>
        </div>
        <!-- End Static Process -->
    </div>
</section>

<!-- Counters -->
<section class="dzsparallaxer auto-init height-is-based-on-content">
    <!-- Parallax Image -->
    <div class="dzsparallaxer--target w-100 g-bg-cover g-bg-size-cover g-bg-pos-center g-bg-black-opacity-0_4--after"
        style="height: 140%; background: url(../../assets/img-temp/1920x800/img8.jpg)"></div>
    <!-- End Parallax Image -->

    <div class="container u-bg-overlay__inner g-pt-130 g-pb-70">
        <div class="row text-center g-color-white">
            <div class="col-md-3 col-6 d-flex justify-content-center g-mb-60">
                <div
                    class="d-flex justify-content-center u-counter-v3 g-brd-white g-bg-white-opacity-0_2 g-width-150 g-height-150 rounded-circle g-pa-20">
                    <div class="align-self-center">
                        <div class="js-counter g-font-size-30 g-font-weight-300">10</div>
                        <h4 class="h5">Developers</h4>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-6 d-flex justify-content-center g-mb-60">
                <div
                    class="d-flex justify-content-center u-counter-v3 g-brd-white g-bg-white-opacity-0_2 g-width-150 g-height-150 rounded-circle g-pa-20">
                    <div class="align-self-center">
                        <div class="js-counter g-font-size-30 g-font-weight-300">6</div>
                        <h4 class="h5">Products</h4>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-6 d-flex justify-content-center g-mb-60">
                <div
                    class="d-flex justify-content-center u-counter-v3 g-brd-white g-bg-white-opacity-0_2 g-width-150 g-height-150 rounded-circle g-pa-20">
                    <div class="align-self-center">
                        <div class="js-counter g-font-size-30 g-font-weight-300">10</div>
                        <h4 class="h5">Customers</h4>
                    </div>
                </div>
            </div>

            <div class="col-md-3 col-6 d-flex justify-content-center g-mb-60">
                <div
                    class="d-flex justify-content-center u-counter-v3 g-brd-white g-bg-white-opacity-0_2 g-width-150 g-height-150 rounded-circle g-pa-20">
                    <div class="align-self-center">
                        <div class="js-counter g-font-size-30 g-font-weight-300">5</div>
                        <h4 class="h5">Projects</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- Counters -->