import { Component, OnInit } from '@angular/core';
import { UIManager } from '../../util/ui-manager';

@Component({
  selector: 'app-comingsoon',
  templateUrl: './comingsoon.component.html',
  styleUrls: ['./comingsoon.component.css']
})
export class ComingsoonComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    UIManager.initCountdown();
  }

}
