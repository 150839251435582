<!-- Promo Block -->
<section class="g-pos-rel">
    <!-- Promo Block - Slider -->
    <div class="js-carousel" data-autoplay="true" data-infinite="true" data-fade="true" data-speed="5000">
        <div class="js-slide g-min-height-100vh g-flex-centered g-bg-cover g-bg-pos-top-center g-bg-img-hero g-bg-black-opacity-0_3--after"
            style="background-image: url(../../assets/img-temp/1920x1080/img20.jpg);"></div>

        <div class="js-slide g-min-height-100vh g-flex-centered g-bg-cover g-bg-pos-top-center g-bg-img-hero g-bg-black-opacity-0_3--after"
            style="background-image: url(../../assets/img-temp/1920x1080/img17.jpg);"></div>
    </div>
    <!-- End Promo Block - Slider -->

    <!-- Promo Block Content -->
    <div class="g-absolute-centered--y g-left-0 g-right-0">
        <div class="container g-z-index-1">
            <div class="g-max-width-600">
                <h1 class="g-color-white g-font-weight-700 g-font-size-40 g-font-size-60--md g-mb-30">Next Evolve</h1>
                <p class="h3 g-color-white g-font-weight-300 g-letter-spacing-1 mb-5">Respect for Individual, Integrity,
                    Service Continuous Im- provement and Technology as leverage</p>
            </div>

            <div class="input-group u-shadow-v19 g-rounded-50 g-max-width-400">
                <input
                    class="form-control g-font-size-default g-color-gray-dark-v4 g-placeholder-gray-dark-v3 border-0 g-rounded-left-50 g-px-20"
                    type="email" placeholder="Enter email" aria-label="Enter email">
                <div class="input-group-append">
                    <span class="input-group-text g-bg-white border-0 g-rounded-right-50">
                        <button class="btn u-btn-primary g-width-40 g-height-40 rounded-circle" type="submit">
                            <i class="fa fa-send"></i>
                        </button>
                    </span>
                </div>
            </div>
        </div>
    </div>
    <!-- End Promo Block Content -->
</section>
<!-- End Promo Block -->


<div class="shortcode-html">
    <!-- Hero Info #07 -->
    <section class="g-py-50--md g-py-30">
        <div class="container text-center">
            <div class="row">
                <div class="col-md-8 ml-md-auto mr-md-auto">
                    <div class="u-heading-v7-2 g-mb-30">
                        <h2 class="display-4 u-heading-v7__title g-mb-20">Welcome to
                            <span class="g-color-primary">Next Evolve!</span>
                        </h2>
                        <i class="fa fa-star g-color-primary g-font-size-70x"></i>
                        <i class="fa fa-star g-font-size-95x g-color-primary"></i>
                        <i class="fa fa-star g-color-primary"></i>
                        <i class="fa fa-star g-font-size-95x g-color-primary"></i>
                        <i class="fa fa-star g-font-size-70x g-color-primary"></i>
                    </div>

                    <p class="lead g-mb-60">We aspire to be the partner of choice for our customers, suppliers,
                        employees and shareholders by accelerating their success through our global technology marketing
                        and distribution resources, services and culture. Our core values are Integrity, Customer
                        Service, Accountability, Teamwork, and Innovation</p>
                </div>
            </div>
        </div>
    </section>
    <!-- Hero Info #07 -->
</div>


<!-- About -->
<section class="g-pt-50 g-pb-50">
    <div class="container">
        <!-- Image, Text Block -->
        <div class="row d-flex align-items-lg-center flex-wrap g-mb-60 g-mb-0--lg">
            <div class="col-md-6 col-lg-8">
                <img class="img-fluid rounded" src="../../assets/img-temp/900x600/img1.jpg" alt="Image Description">
            </div>
            <div class="col-md-6 col-lg-4">
                <div class="g-mt-minus-30 g-mt-0--md g-ml-minus-100--lg">
                    <div class="g-mb-20">
                        <h2
                            class="g-color-black g-font-weight-600 g-font-size-25 g-font-size-35--lg g-line-height-1_2 mb-4">
                            Strategy, Implementation<br>and Customer Support
                        </h2>
                        <p class="g-font-size-16">We take your business very seriously ans strive to plan effectively,
                            execute efficiently and support you, our client, throughout the process and after delivery.
                        </p>
                    </div>
                    <a class="btn u-btn-primary g-brd-2 g-brd-white g-font-size-13 g-rounded-50 g-pl-20 g-pr-15 g-py-9"
                        routerLink="/portfolios">
                        More products
                        <span
                            class="align-middle u-icon-v3 g-width-16 g-height-16 g-color-black g-bg-white g-font-size-11 rounded-circle ml-3">
                            <i class="fa fa-angle-right"></i>
                        </span>
                    </a>
                </div>
            </div>
        </div>
        <!-- End Image, Text Block -->
    </div>

    <div class="container">
        <!-- Image, Text Block -->
        <div class="row d-flex justify-content-between align-items-lg-center flex-wrap g-mt-minus-50--lg">
            <div class="col-md-6 order-md-2">
                <div class="g-brd-around--md g-brd-10 g-brd-white rounded">
                    <img class="img-fluid w-100 rounded" src="../../assets/img-temp/600x450/img1.jpg"
                        alt="Image Description">
                </div>
            </div>
            <div class="col-md-6 col-lg-4 ml-auto order-md-1">
                <div class="g-mt-minus-30 g-mt-0--md g-ml-minus-100--lg">
                    <div class="g-mb-20">
                        <h2
                            class="g-color-black g-font-weight-600 g-font-size-25 g-font-size-35--lg g-line-height-1_2 mb-4">
                            Building the<br>Perfect Product
                        </h2>
                        <p class="g-font-size-16">We are committed to building and delivering the perfect product to
                            suit your needs. Through detailed planning, execution and testing.</p>
                    </div>
                    <a class="btn u-btn-primary g-brd-2 g-brd-white g-font-size-13 g-rounded-50 g-pl-20 g-pr-15 g-py-9"
                        href="#">
                        Read more
                        <span
                            class="align-middle u-icon-v3 g-width-16 g-height-16 g-color-black g-bg-white g-font-size-11 rounded-circle ml-3">
                            <i class="fa fa-angle-right"></i>
                        </span>
                    </a>
                </div>
            </div>
        </div>
        <!-- End Image, Text Block -->
    </div>
</section>
<!-- End About -->

<!-- Icon Blocks -->
<section class="g-pos-rel g-overflow-hidden">
    <div class="container g-pt-100 g-pb-50">
        <div class="row justify-content-between">
            <div class="col-lg-5 g-mb-50">
                <!-- Heading -->
                <div class="u-heading-v6-2 g-mb-60">
                    <h2 class="u-heading-v6__title g-color-black g-font-weight-600 g-mb-30">The Benefits<br>of Working
                        With Us
                    </h2>
                    <p class=" g-pl-90">The time has come to bring those ideas and plans to life. This is where we
                        really begin to visualize your thoughts and make them into beautiful products.</p>
                </div>
                <!-- End Heading -->
            </div>

            <div class="col-lg-6">
                <!-- Icon Blocks -->
                <div class="row">
                    <div class="col-sm-6 text-center text-lg-left g-mb-50">
                        <!-- Icon Blocks -->
                        <span
                            class="u-icon-v1 u-icon-size--lg g-color-teal g-bg-teal-opacity-0_1 g-font-size-18 rounded-circle mb-4">
                            <i class="icon-education-087 u-line-icon-pro"></i>
                        </span>
                        <h3 class="h5 g-color-black g-font-weight-600 mb-2">Trustworthy</h3>
                        <p class="mb-0">Absolute faith our clients place in us to deliver the best quality, as at when
                            required</p>
                        <!-- End Icon Blocks -->
                    </div>

                    <div class="col-sm-6 text-center text-lg-left g-mb-50">
                        <!-- Icon Blocks -->
                        <span
                            class="u-icon-v1 u-icon-size--lg g-color-cyan g-bg-cyan-opacity-0_1 g-font-size-18 rounded-circle mb-4">
                            <i class="icon-education-035 u-line-icon-pro"></i>
                        </span>
                        <h3 class="h5 g-color-black g-font-weight-600 mb-2">Helpful</h3>
                        <p class="mb-0">Extensive and exclusive top tier customer support for all our clients needs.</p>
                        <!-- End Icon Blocks -->
                    </div>

                    <div class="col-sm-6 text-center text-lg-left g-mb-50">
                        <!-- Icon Blocks -->
                        <span
                            class="u-icon-v1 u-icon-size--lg g-color-darkblue g-bg-darkblue-opacity-0_1 g-font-size-18 rounded-circle mb-4">
                            <i class="icon-education-141 u-line-icon-pro"></i>
                        </span>
                        <h3 class="h5 g-color-black g-font-weight-600 mb-2">Reliability</h3>
                        <p class="mb-0">Products built to stand the test of time, with no room for crashes or breakdowns
                            during processing. </p>
                        <!-- End Icon Blocks -->
                    </div>

                    <div class="col-sm-6 text-center text-lg-left g-mb-50">
                        <!-- Icon Blocks -->
                        <span
                            class="u-icon-v1 u-icon-size--lg g-color-purple g-bg-purple-opacity-0_1 g-font-size-18 rounded-circle mb-4">
                            <i class="icon-finance-256 u-line-icon-pro"></i>
                        </span>
                        <h3 class="h5 g-color-black g-font-weight-600 mb-2">Great Prices</h3>
                        <p class="mb-0">Offering competitive pricing to match industry standard without renegading on
                            the product quality.</p>
                        <!-- End Icon Blocks -->
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>
        </div>

        <img class="g-width-600 g-pos-abs g-bottom-minus-25x g-z-index-minus-1" src="../../assets/img/maps/map3.png"
            alt="Image Description">
    </div>
</section>
<!-- End Icon Blocks -->