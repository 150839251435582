<!-- Promo Block -->
<section class="g-bg-img-hero" style="background-image: url(../../assets/img-temp/1920x1080/img13.jpg);">
    <div class="container g-pos-rel g-z-index-1 g-py-150">
        <div class="g-mb-40">
            <h1 class="g-color-white g-font-weight-700 g-font-size-80 g-font-size-130--md g-line-height-1_2 mb-0">
                products</h1>
            <span class="d-block g-color-white g-font-size-20 g-pos-rel g-top-minus-20--md ml-md-5">our branding</span>
        </div>

        <div class="text-right">
            <a class="js-fancybox g-text-underline--none--hover mb-5" href="javascript:;"
                data-src="//www.youtube.com/embed/BNpiwOkKIJ8?autoplay=1" data-speed="350"
                data-caption="Our working process">
                <span class="align-middle u-icon-v3 g-bg-white g-color-black g-color-primary--hover g-rounded-50x mr-2">
                    <i class="g-font-size-15 g-pos-rel g-left-2 fa fa-play"></i>
                </span>
                <span class="d-inline-block g-brd-bottom g-brd-white g-color-white g-font-weight-600 text-uppercase">Our
                    working process</span>
            </a>
        </div>
    </div>
</section>
<!-- End Promo Block -->

<!-- Cube Portfolio Blocks -->
<div class="dzsparallaxer auto-init height-is-based-on-content g-bg-white"
    data-options='{direction: "reverse", animation_duration: "200"}'>
    <div class="dzsparallaxer--target" style="width: 100%; height: 130%;">
    </div>

    <div class="container g-py-100">
        <div class="cbp" data-animation="quicksand" data-x-gap="30" data-y-gap="30" data-media-queries='[
               {"width": 1500, "cols": 2},
               {"width": 1100, "cols": 2},
               {"width": 800, "cols": 2},
               {"width": 480, "cols": 2},
               {"width": 300, "cols": 1}
             ]'>
            <!-- Cube Portfolio Blocks - Item -->
            <div class="cbp-item"
                data-parallaxanimation='[{property: "transform", value:" translate3d(0,px,0)", initial:"-100", mid:"0", final:"100"}]'>
                <div class="u-block-hover g-parent">
                    <img class="img-fluid g-transform-scale-1_1--parent-hover g-transition-0_5 g-transition--ease-in-out"
                        src="../../assets/img/custom/call-center-sm.jpg" alt="Image description">
                    <div
                        class="d-flex w-100 u-block-hover__additional--fade u-block-hover__additional--fade-in h-100 g-pos-abs g-top-0 g-left-0 g-transition-0_3 g-transition--ease-in g-pa-20">
                        <ul class="align-items-end flex-column list-inline mt-auto ml-auto mb-0">
                            <li class="list-inline-item">
                                <a class="u-icon-v2 u-icon-size--sm g-brd-white g-color-black g-bg-white rounded-circle">
                                    <i class="icon-communication-095 u-line-icon-pro"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a class="cbp-lightbox u-icon-v2 u-icon-size--sm g-brd-white g-color-black g-bg-white rounded-circle"
                                    href="../../assets/img/custom/call-center.jpg">
                                    <i class="icon-communication-017 u-line-icon-pro"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="text-center g-pa-25">
                    <h3 class="h4 g-color-black mb-1">Call Centre</h3>
                    <p class="g-color-gray-dark-v4 mb-0">Advanced Call Management</p>
                </div>
            </div>
            <!-- End Cube Portfolio Blocks - Item -->

            <!-- Cube Portfolio Blocks - Item -->
            <div class="cbp-item"
                data-parallaxanimation='[{property: "transform", value:" translate3d(0,px,0)", initial:"80", mid:"0", final:"-40"}]'>
                <div class="u-block-hover g-parent">
                    <img class="img-fluid g-transform-scale-1_1--parent-hover g-transition-0_5 g-transition--ease-in-out"
                        src="../../assets/img/custom/cbt-center-sm.jpg" alt="Image description">
                    <div
                        class="d-flex w-100 u-block-hover__additional--fade u-block-hover__additional--fade-in h-100 g-pos-abs g-top-0 g-left-0 g-transition-0_3 g-transition--ease-in g-pa-20">
                        <ul class="align-items-end flex-column list-inline mt-auto ml-auto mb-0">
                            <li class="list-inline-item">
                                <a class="u-icon-v2 u-icon-size--sm g-brd-white g-color-black g-bg-white rounded-circle">
                                    <i class="icon-communication-095 u-line-icon-pro"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a class="cbp-lightbox u-icon-v2 u-icon-size--sm g-brd-white g-color-black g-bg-white rounded-circle"
                                    href="../../assets/img/custom/cbt-center.jpeg">
                                    <i class="icon-communication-017 u-line-icon-pro"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="text-center g-pa-25">
                    <h3 class="h4 g-color-black mb-1">CBT Centre</h3>
                    <p class="g-color-gray-dark-v4 mb-0">Computer Based Test Software & Setup</p>
                </div>
            </div>
            <!-- End Cube Portfolio Blocks - Item -->

            <!-- Cube Portfolio Blocks - Item -->
            <div class="cbp-item"
                data-parallaxanimation='[{property: "transform", value:" translate3d(0,px,0)", initial:"-100", mid:"0", final:"100"}]'>
                <div class="u-block-hover g-parent">
                    <img class="img-fluid g-transform-scale-1_1--parent-hover g-transition-0_5 g-transition--ease-in-out"
                        src="../../assets/img/custom/hopital-mgt-sm.jpg" alt="Image description">
                    <div
                        class="d-flex w-100 u-block-hover__additional--fade u-block-hover__additional--fade-in h-100 g-pos-abs g-top-0 g-left-0 g-transition-0_3 g-transition--ease-in g-pa-20">
                        <ul class="align-items-end flex-column list-inline mt-auto ml-auto mb-0">
                            <li class="list-inline-item">
                                <a class="u-icon-v2 u-icon-size--sm g-brd-white g-color-black g-bg-white rounded-circle">
                                    <i class="icon-communication-095 u-line-icon-pro"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a class="cbp-lightbox u-icon-v2 u-icon-size--sm g-brd-white g-color-black g-bg-white rounded-circle"
                                    href="../../assets/img/custom/hopital-mgt.jpeg">
                                    <i class="icon-communication-017 u-line-icon-pro"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="text-center g-pa-25">
                    <h3 class="h4 g-color-black mb-1">HMS Software</h3>
                    <p class="g-color-gray-dark-v4 mb-0">Hospital Management System</p>
                </div>
            </div>
            <!-- End Cube Portfolio Blocks - Item -->

            <!-- Cube Portfolio Blocks - Item -->
            <div class="cbp-item"
                data-parallaxanimation='[{property: "transform", value:" translate3d(0,px,0)", initial:"80", mid:"0", final:"-40"}]'>
                <div class="u-block-hover g-parent">
                    <img class="img-fluid g-transform-scale-1_1--parent-hover g-transition-0_5 g-transition--ease-in-out"
                        src="../../assets/img/custom/school-mgt-sm.jpg" alt="Image description">
                    <div
                        class="d-flex w-100 u-block-hover__additional--fade u-block-hover__additional--fade-in h-100 g-pos-abs g-top-0 g-left-0 g-transition-0_3 g-transition--ease-in g-pa-20">
                        <ul class="align-items-end flex-column list-inline mt-auto ml-auto mb-0">
                            <li class="list-inline-item">
                                <a class="u-icon-v2 u-icon-size--sm g-brd-white g-color-black g-bg-white rounded-circle">
                                    <i class="icon-communication-095 u-line-icon-pro"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a class="cbp-lightbox u-icon-v2 u-icon-size--sm g-brd-white g-color-black g-bg-white rounded-circle"
                                    href="../../assets/img/custom/school-mgt.png">
                                    <i class="icon-communication-017 u-line-icon-pro"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="text-center g-pa-25">
                    <h3 class="h4 g-color-black mb-1">School Management</h3>
                    <p class="g-color-gray-dark-v4 mb-0">School Administration Software</p>
                </div>
            </div>
            <!-- End Cube Portfolio Blocks - Item -->

            <!-- Cube Portfolio Blocks - Item -->
            <div class="cbp-item"
                data-parallaxanimation='[{property: "transform", value:" translate3d(0,px,0)", initial:"-100", mid:"0", final:"100"}]'>
                <div class="u-block-hover g-parent">
                    <img class="img-fluid g-transform-scale-1_1--parent-hover g-transition-0_5 g-transition--ease-in-out"
                        src="../../assets/img/custom/inventory-mgt-2-sm.jpg" alt="Image description">
                    <div
                        class="d-flex w-100 u-block-hover__additional--fade u-block-hover__additional--fade-in h-100 g-pos-abs g-top-0 g-left-0 g-transition-0_3 g-transition--ease-in g-pa-20">
                        <ul class="align-items-end flex-column list-inline mt-auto ml-auto mb-0">
                            <li class="list-inline-item">
                                <a class="u-icon-v2 u-icon-size--sm g-brd-white g-color-black g-bg-white rounded-circle">
                                    <i class="icon-communication-095 u-line-icon-pro"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a class="cbp-lightbox u-icon-v2 u-icon-size--sm g-brd-white g-color-black g-bg-white rounded-circle"
                                    href="../../assets/img/custom/inventory-mgt-2.png">
                                    <i class="icon-communication-017 u-line-icon-pro"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="text-center g-pa-25">
                    <h3 class="h4 g-color-black mb-1">Inventory Software</h3>
                    <p class="g-color-gray-dark-v4 mb-0">Top Line Invertory Manager</p>
                </div>
            </div>
            <!-- End Cube Portfolio Blocks - Item -->
        </div>
    </div>
</div>
<!-- End Cube Portfolio Blocks -->

<!-- Icon Blocks -->
<section class="g-bg-secondary">
    <div class="container g-py-100">
        <div class="row justify-content-center g-mb-60">
            <div class="col-lg-7">
                <!-- Heading -->
                <div class="text-center">
                    <h2 class="h3 g-color-black text-uppercase mb-2">Our features</h2>
                    <div class="d-inline-block g-width-35 g-height-2 g-bg-primary mb-2"></div>
                    <p class="lead mb-0">We are a creative company focusing on flexibility, attention to detail, rigour
                        &amp; excellence.
                        Somewhere between sophistication and simplicity.</p>
                </div>
                <!-- End Heading -->
            </div>
        </div>

        <!-- Icon Blocks -->
        <div class="row">
            <div class="col-lg-6 g-mb-30">
                <!-- Icon Blocks -->
                <div class="u-shadow-v19 g-bg-white rounded g-pa-30">
                    <div class="media g-mb-15">
                        <div class="d-flex mr-4">
                            <span class="g-color-gray-dark-v4 g-font-size-26">
                                <i class="icon-education-087 u-line-icon-pro"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h3 class="h5 g-color-black mb-20">Creative ideas</h3>
                            <div class="g-width-30 g-brd-bottom g-brd-gray-dark-v5 g-my-15"></div>
                            <p class="g-color-gray-dark-v4 g-mb-0">We strive to embrace and drive change in our industry
                                which allows us to keep our clients relevant.</p>
                        </div>
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>

            <div class="col-lg-6 g-mb-30">
                <!-- Icon Blocks -->
                <div class="u-shadow-v19 g-bg-white rounded g-pa-30">
                    <div class="media g-mb-15">
                        <div class="d-flex mr-4">
                            <span class="g-color-gray-dark-v4 g-font-size-26">
                                <i class="icon-education-035 u-line-icon-pro"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h3 class="h5 g-color-black mb-20">Excellent features</h3>
                            <div class="g-width-30 g-brd-bottom g-brd-gray-dark-v5 g-my-15"></div>
                            <p class="g-color-gray-dark-v4 g-mb-0">Building solutions with the most cutting edge
                                technological advancements to best suit the customer needs.</p>
                        </div>
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>
        </div>
        <!-- End Icon Blocks -->

        <!-- Icon Blocks -->
        <div class="row">
            <div class="col-lg-6 g-mb-30">
                <!-- Icon Blocks -->
                <div class="u-shadow-v19 g-bg-white rounded g-pa-30">
                    <div class="media g-mb-15">
                        <div class="d-flex mr-4">
                            <span class="g-color-gray-dark-v4 g-font-size-26">
                                <i class="icon-education-141 u-line-icon-pro"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h3 class="h5 g-color-black mb-20">Extensive documentation</h3>
                            <div class="g-width-30 g-brd-bottom g-brd-gray-dark-v5 g-my-15"></div>
                            <p class="g-color-gray-dark-v4 g-mb-0">All products are fully documented, allowing
                                clients to easily understand and use our products with little or no guidance.
                            </p>
                        </div>
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>

            <div class="col-lg-6 g-mb-30">
                <!-- Icon Blocks -->
                <div class="u-shadow-v19 g-bg-white rounded g-pa-30">
                    <div class="media g-mb-15">
                        <div class="d-flex mr-4">
                            <span class="g-color-gray-dark-v4 g-font-size-26">
                                <i class="icon-finance-256 u-line-icon-pro"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h3 class="h5 g-color-black mb-20">Clean code</h3>
                            <div class="g-width-30 g-brd-bottom g-brd-gray-dark-v5 g-my-15"></div>
                            <p class="g-color-gray-dark-v4 g-mb-0">Products are built with clean code to ensure easy
                                maintainability and reduce code dept to less than 1%</p>
                        </div>
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>
        </div>
        <!-- End Icon Blocks -->

        <!-- Icon Blocks -->
        <div class="row">
            <div class="col-lg-6 g-mb-30">
                <!-- Icon Blocks -->
                <div class="u-shadow-v19 g-bg-white rounded g-pa-30">
                    <div class="media g-mb-15">
                        <div class="d-flex mr-4">
                            <span class="g-color-gray-dark-v4 g-font-size-26">
                                <i class="icon-science-020 u-line-icon-pro"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h3 class="h5 g-color-black mb-20">Reliability</h3>
                            <div class="g-width-30 g-brd-bottom g-brd-gray-dark-v5 g-my-15"></div>
                            <p class="g-color-gray-dark-v4 g-mb-0">Be it a simple or complex application or system, we
                                build a absolute reliability into our systems to ensure our clients have 99.99% uptime
                                on our products.</p>
                        </div>
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>

            <div class="col-lg-6 g-mb-30">
                <!-- Icon Blocks -->
                <div class="u-shadow-v19 g-bg-white rounded g-pa-30">
                    <div class="media g-mb-15">
                        <div class="d-flex mr-4">
                            <span class="g-color-gray-dark-v4 g-font-size-26">
                                <i class="icon-finance-009 u-line-icon-pro"></i>
                            </span>
                        </div>
                        <div class="media-body">
                            <h3 class="h5 g-color-black mb-20">Modern design</h3>
                            <div class="g-width-30 g-brd-bottom g-brd-gray-dark-v5 g-my-15"></div>
                            <p class="g-color-gray-dark-v4 g-mb-0">We employ the very best of design structures for our
                                products ensuring our clients and their customers get the absolute best experience on
                                the platform</p>
                        </div>
                    </div>
                </div>
                <!-- End Icon Blocks -->
            </div>
        </div>
        <!-- End Icon Blocks -->
    </div>
</section>
<!-- End Icon Blocks -->