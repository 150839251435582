<!-- Promo Block -->
<section class="dzsparallaxer auto-init height-is-based-on-content use-loading mode-scroll loaded dzsprx-readyall "
    data-options='{direction: "fromtop", animation_duration: 25, direction: "reverse"}'>
    <!-- Parallax Image -->
    <div class="divimage dzsparallaxer--target w-100 u-bg-overlay g-bg-black-opacity-0_3--after"
        style="height: 140%; background-image: url(../../assets/img-temp/1920x800/img3.jpg);"></div>
    <!-- End Parallax Image -->

    <!-- Promo Block Content -->
    <div class="container g-color-white text-center g-py-120">
        <h3 class="h1 g-font-weight-600 text-uppercase mb-2">About us</h3>
        <p class="g-font-weight-300 g-font-size-22 text-uppercase">Respect for Individual, Integrity, Service Continuous
            Improvement and Technology as leverage</p>
    </div>
    <!-- Promo Block Content -->
</section>
<!-- End Promo Block -->

<!-- Team Blocks -->
<section class="container g-pt-100 g-pb-70">
    <div class="row justify-content-center g-mb-60">
        <div class="col-lg-7">
            <!-- Heading -->
            <div class="text-center">
                <h2 class="h3 g-color-black text-uppercase mb-2">Meet your creative team</h2>
                <div class="d-inline-block g-width-35 g-height-2 g-bg-primary mb-2"></div>
                <p class="lead mb-0">We aspire to be the partner of choice for our customers, suppliers, employees and
                    shareholders by accelerating their success through our global technology marketing and distribution
                    resources, services and culture.</p>
            </div>
            <!-- End Heading -->
        </div>
    </div>

    <div class="row">
        <div class="col-lg-3 col-sm-6 g-mb-30">
            <!-- Team Block -->
            <figure class="u-block-hover">
                <!-- Figure Image -->
                <img class="w-100" src="../../assets/img/default/400x550/img1.jpg" alt="Image description">
                <!-- End Figure Image-->

                <!-- Figure Info -->
                <figcaption class="u-block-hover__additional--partially-slide-up g-mx-15">
                    <div class="text-center g-bg-white g-pb-20">
                        <!-- Figure Info -->
                        <div class="u-block-hover__visible g-pt-20 g-pb-15 g-y-20">
                            <h4 class="h5 g-color-black g-mb-5">Joshua Adamu</h4>
                            <em
                                class="d-block g-font-style-normal g-font-size-11 text-uppercase g-color-primary">C.E.O</em>
                        </div>
                        <!-- End Info -->

                        <!-- Figure Social Icons -->
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a href="https://www.linkedin.com/in/joshua-adamu-32b41029/" target="_blank"
                                    class="u-icon-v1 u-icon-size--sm g-color-gray-light-v1 g-color-twitter--hover g-bg-transparent">
                                    <i class="fa fa-linkedin"></i>
                                </a>
                            </li>
                            <!-- <li class="list-inline-item">
                                <a href="#"
                                    class="u-icon-v1 u-icon-size--sm g-color-gray-light-v1 g-color-dribbble--hover g-bg-transparent">
                                    <i class="fa fa-dribbble"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#"
                                    class="u-icon-v1 u-icon-size--sm g-color-gray-light-v1 g-color-facebook--hover g-bg-transparent">
                                    <i class="fa fa-facebook"></i>
                                </a>
                            </li> -->
                        </ul>
                        <!-- Figure Social Icons -->
                    </div>
                </figcaption>
                <!-- End Figure Info-->
            </figure>
            <!-- End Team Block -->
        </div>

        <div class="col-lg-3 col-sm-6 g-mb-30">
            <!-- Team Block -->
            <figure class="u-block-hover">
                <!-- Figure Image -->
                <img class="w-100" src="../../assets/img/default/400x550/img1.jpg" alt="Image description">
                <!-- End Figure Image-->

                <!-- Figure Info -->
                <figcaption class="u-block-hover__additional--partially-slide-up g-mx-15">
                    <div class="text-center g-bg-white g-pb-20">
                        <!-- Figure Info -->
                        <div class="u-block-hover__visible g-pt-20 g-pb-15 g-y-20">
                            <h4 class="h5 g-color-black g-mb-5">Joy Adamu</h4>
                            <em
                                class="d-block g-font-style-normal g-font-size-11 text-uppercase g-color-primary">Lead Human Resource</em>
                        </div>
                        <!-- End Info -->

                        <!-- Figure Social Icons -->
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a href="https://www.linkedin.com/in/joshua-adamu-32b41029/" target="_blank"
                                    class="u-icon-v1 u-icon-size--sm g-color-gray-light-v1 g-color-twitter--hover g-bg-transparent">
                                    <i class="fa fa-linkedin"></i>
                                </a>
                            </li>
                            <!-- <li class="list-inline-item">
                                <a href="#"
                                    class="u-icon-v1 u-icon-size--sm g-color-gray-light-v1 g-color-dribbble--hover g-bg-transparent">
                                    <i class="fa fa-dribbble"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#"
                                    class="u-icon-v1 u-icon-size--sm g-color-gray-light-v1 g-color-facebook--hover g-bg-transparent">
                                    <i class="fa fa-facebook"></i>
                                </a>
                            </li> -->
                        </ul>
                        <!-- Figure Social Icons -->
                    </div>
                </figcaption>
                <!-- End Figure Info-->
            </figure>
            <!-- End Team Block -->
        </div>

        <div class="col-lg-3 col-sm-6 g-mb-30">
            <!-- Team Block -->
            <figure class="u-block-hover">
                <!-- Figure Image -->
                <img class="w-100" src="../../assets/img/default/400x550/img1.jpg" alt="Image description">
                <!-- End Figure Image-->

                <!-- Figure Info -->
                <figcaption class="u-block-hover__additional--partially-slide-up g-mx-15">
                    <div class="text-center g-bg-white g-pb-20">
                        <!-- Figure Info -->
                        <div class="u-block-hover__visible g-pt-20 g-pb-15 g-y-20">
                            <h4 class="h5 g-color-black g-mb-5">Opeyemi Akinnawo</h4>
                            <em class="d-block g-font-style-normal g-font-size-11 text-uppercase g-color-primary">Lead
                                Developer</em>
                        </div>
                        <!-- End Info -->

                        <!-- Figure Social Icons -->
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a href="https://www.linkedin.com/in/opeyemi-akinnawo-1b879916/"  target="_blank"
                                    class="u-icon-v1 u-icon-size--sm g-color-gray-light-v1 g-color-twitter--hover g-bg-transparent">
                                    <i class="fa fa-linkedin"></i>
                                </a>
                            </li>
                            <!-- <li class="list-inline-item">
                                <a href="#"
                                    class="u-icon-v1 u-icon-size--sm g-color-gray-light-v1 g-color-dribbble--hover g-bg-transparent">
                                    <i class="fa fa-dribbble"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#"
                                    class="u-icon-v1 u-icon-size--sm g-color-gray-light-v1 g-color-facebook--hover g-bg-transparent">
                                    <i class="fa fa-facebook"></i>
                                </a>
                            </li> -->
                        </ul>
                        <!-- Figure Social Icons -->
                    </div>
                </figcaption>
                <!-- End Figure Info-->
            </figure>
            <!-- End Team Block -->
        </div>

        <div class="col-lg-3 col-sm-6 g-mb-30">
            <!-- Team Block -->
            <figure class="u-block-hover">
                <!-- Figure Image -->
                <img class="w-100" src="../../assets/img/default/400x550/img1.jpg" alt="Image description">
                <!-- End Figure Image-->

                <!-- Figure Info -->
                <figcaption class="u-block-hover__additional--partially-slide-up g-mx-15">
                    <div class="text-center g-bg-white g-pb-20">
                        <!-- Figure Info -->
                        <div class="u-block-hover__visible g-pt-20 g-pb-15 g-y-20">
                            <h4 class="h5 g-color-black g-mb-5">Ronke Akinfolarin</h4>
                            <em class="d-block g-font-style-normal g-font-size-11 text-uppercase g-color-primary">Lead
                                Project Manager</em>
                        </div>
                        <!-- End Info -->

                        <!-- Figure Social Icons -->
                        <ul class="list-inline mb-0">
                            <li class="list-inline-item">
                                <a href="https://www.linkedin.com/in/ronke-akinfolarin-226312196/" target="_blank"
                                    class="u-icon-v1 u-icon-size--sm g-color-gray-light-v1 g-color-twitter--hover g-bg-transparent">
                                    <i class="fa fa-linkedin"></i>
                                </a>
                            </li>
                            <!-- <li class="list-inline-item">
                                <a href="#"
                                    class="u-icon-v1 u-icon-size--sm g-color-gray-light-v1 g-color-dribbble--hover g-bg-transparent">
                                    <i class="fa fa-dribbble"></i>
                                </a>
                            </li>
                            <li class="list-inline-item">
                                <a href="#"
                                    class="u-icon-v1 u-icon-size--sm g-color-gray-light-v1 g-color-facebook--hover g-bg-transparent">
                                    <i class="fa fa-facebook"></i>
                                </a>
                            </li> -->
                        </ul>
                        <!-- Figure Social Icons -->
                    </div>
                </figcaption>
                <!-- End Figure Info-->
            </figure>
            <!-- End Team Block -->
        </div>
    </div>
</section>
<!-- End Team Blocks -->

<!-- Testimonials -->
<section class="g-bg-gray-light-v5 g-py-100">
    <div class="container">
        <div class="row justify-content-center g-mb-60">
            <div class="col-lg-7">
                <!-- Heading -->
                <div class="text-center">
                    <h2 class="h3 g-color-black text-uppercase mb-2">What people say about Next Evolve?</h2>
                    <div class="d-inline-block g-width-35 g-height-2 g-bg-primary mb-2"></div>
                    <p class="lead mb-0">Next Evolve Limited Is Committed In Bringing Professional And Expertise Towards
                        Achieving Project, Values And Aspiration Drives Everything We Do.</p>
                </div>
                <!-- End Heading -->
            </div>
        </div>

        <div class="row">
            <div class="col-lg-6 g-mb-30">
                <!-- Testimonials -->
                <div
                    class="media g-brd-bottom g-brd-3 g-brd-gray-light-v4 g-brd-primary--hover g-bg-white g-rounded-4 g-transition-0_3 g-pa-20">
                    <div class="d-flex mr-4">
                        <img class="g-width-70 g-height-70 rounded-circle" src="../../assets/img-temp/100x100/img14.jpg"
                            alt="Image description">
                    </div>
                    <div class="media-body">
                        <blockquote class="lead g-mb-15">We've noticed a 200 percent rise in the amount of online
                            contact since having our new website designed by Next Evolve.</blockquote>
                        <h4 class="h6 g-font-weight-700 g-mb-0">Emma Smith</h4>
                        <em class="g-color-gray-dark-v4 g-font-style-normal g-font-size-13">Customer</em>
                    </div>
                </div>
                <!-- End Testimonials -->
            </div>

            <div class="col-lg-6 g-mb-30">
                <!-- Testimonials -->
                <div
                    class="media g-brd-bottom g-brd-3 g-brd-gray-light-v4 g-brd-primary--hover g-bg-white g-rounded-4 g-transition-0_3 g-pa-20">
                    <div class="d-flex mr-4">
                        <img class="g-width-70 g-height-70 rounded-circle" src="../../assets/img-temp/100x100/img15.jpg"
                            alt="Image description">
                    </div>
                    <div class="media-body">
                        <blockquote class="lead g-mb-15">Joshua and his team collaborated extensively with us to create
                            a site that satisfied all of our requirements resulting in an effective website.
                        </blockquote>
                        <h4 class="h6 g-font-weight-700 g-mb-0">Anna Pretty</h4>
                        <em class="g-color-gray-dark-v4 g-font-style-normal g-font-size-13">Customer</em>
                    </div>
                </div>
                <!-- End Testimonials -->
            </div>

            <div class="col-lg-6 g-mb-30">
                <!-- Testimonials -->
                <div
                    class="media g-brd-bottom g-brd-3 g-brd-gray-light-v4 g-brd-primary--hover g-bg-white g-rounded-4 g-transition-0_3 g-pa-20">
                    <div class="d-flex mr-4">
                        <img class="g-width-70 g-height-70 rounded-circle" src="../../assets/img-temp/100x100/img16.jpg"
                            alt="Image description">
                    </div>
                    <div class="media-body">
                        <blockquote class="lead g-mb-15">We had a fantastic and delightful time working with Next
                            Evolve. They used
                            cutting-edge procedures and delivered on schedule, with exceptional quality.</blockquote>
                        <h4 class="h6 g-font-weight-700 g-mb-0">Danilla Ut.</h4>
                        <em class="g-color-gray-dark-v4 g-font-style-normal g-font-size-13">Customer</em>
                    </div>
                </div>
                <!-- End Testimonials -->
            </div>

            <div class="col-lg-6 g-mb-30">
                <!-- Testimonials -->
                <div
                    class="media g-brd-bottom g-brd-3 g-brd-gray-light-v4 g-brd-primary--hover g-bg-white g-rounded-4 g-transition-0_3 g-pa-20">
                    <div class="d-flex mr-4">
                        <img class="g-width-70 g-height-70 rounded-circle" src="../../assets/img-temp/100x100/img17.jpg"
                            alt="Image description">
                    </div>
                    <div class="media-body">
                        <blockquote class="lead g-mb-15">We hired Next Evolve as a strategic IT partner to
                            develop a corporate ERP solution. The organization proved to be a dependable IT
                            service supplier.</blockquote>
                        <h4 class="h6 g-font-weight-700 g-mb-0">Alex Pottorf</h4>
                        <em class="g-color-gray-dark-v4 g-font-style-normal g-font-size-13">Customer</em>
                    </div>
                </div>
                <!-- End Testimonials -->
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials -->