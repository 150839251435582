import { Component, OnInit } from '@angular/core';
import { UIManager } from '../../util/ui-manager';

@Component({
  selector: 'app-portfolio',
  templateUrl: './portfolio.component.html',
  styleUrls: ['./portfolio.component.css']
})
export class PortfolioComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    UIManager.initCubePortfolio();
  }

}
